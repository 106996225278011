import React, { useState, useEffect } from "react";

const Contact = ({ data }) => {
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//rf.revolvermaps.com/0/0/8.js?i=5n0c9bq...";
    script.type = "text/javascript";
    script.async = true;
    document.getElementById("revolver-map").appendChild(script);

    // Custom styles to hide zoom buttons
    const style = document.createElement("style");
    style.innerHTML = `
      #revolver-map {
        position: relative;
        overflow: hidden;
        height: 250px; /* Set 50px less than the full height */
      }
      #revolver-map iframe {
        position: relative;
        height: 300px; /* Full height */
        width: 300px; /* Full width */
        transform: translateY(-50px); /* Shift the content upwards by 50px */
      }
    `;
    document.head.appendChild(style);
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
  };

  return (
    <section id="contact">
      <div className="row section-head">
        <div className="two columns header-col">
          <h1>
            <span>Get In Touch.</span>
          </h1>
        </div>

        <div className="ten columns">
          <p className="lead">{data?.message}</p>
        </div>
      </div>

      <div className="row">
        <div className="eight columns">
          <form
            id="contactForm"
            name="contactForm"
            action="https://formspree.io/f/mgvwbyqb"
            method="POST"
          >
            <fieldset>
              <div>
                <label htmlFor="contactName">
                  Name <span className="required">*</span>
                </label>
                <input
                  value={name}
                  type="text"
                  defaultValue=""
                  size="35"
                  id="contactName"
                  name="contactName"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="contactEmail">
                  Email <span className="required">*</span>
                </label>
                <input
                  value={email}
                  type="email"
                  name="email"
                  defaultValue=""
                  size="35"
                  id="contactEmail"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="contactSubject">Subject</label>
                <input
                  value={subject}
                  type="text"
                  defaultValue=""
                  size="35"
                  id="contactSubject"
                  name="contactSubject"
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="contactMessage">
                  Message <span className="required">*</span>
                </label>
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  cols="50"
                  rows="15"
                  id="contactMessage"
                  name="message"
                ></textarea>
              </div>

              <div>
                <button type="submit" className="submit">
                  Submit
                </button>
                <span id="image-loader">
                  <img alt="" src="images/loader.gif" />
                </span>
              </div>
            </fieldset>
          </form>

          <div id="message-warning"> Error boy</div>
          <div id="message-success">
            <i className="fa fa-check"></i>Your message was sent, thank you!
            <br />
          </div>
        </div>

        <aside className="four columns footer-widgets">
          <div className="widget widget_contact">
            <h4>Address and Phone</h4>
            <p className="address">
              {data?.name}
              <br />
              {data?.address.street} <br />
              {data?.address.city}, {data?.address.state} {data?.address.zip}
              <br />
              <span>{data?.phone}</span>
            </p>
          </div>

          {/* RevolverMap widget with cut bottom */}
          <div id="revolver-map" style={{ width: '300px',height:'193px' }}>
            {/* Map will be loaded here */}
          </div>
        </aside>
      </div>
    </section>
  );
};

export default Contact;
