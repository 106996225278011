import React, { Component } from 'react';

class Resume extends Component {
  render() {
    if (this.props.data) {
      const skillmessage = this.props.data.skillmessage;
      const education = this.props.data.education.map((education) => (
        <div key={education.school}>
          <h3>{education.school}</h3>
          <p className="info">
            {education.degree} <span>&bull;</span><em className="date">{education.graduated}</em>
          </p>
          <p>{education.description}</p>
        </div>
      ));
      const work = this.props.data.work.map((work) => (
        <div key={work.company}>
          <h3>{work.company}</h3>
          <p className="info">
            {work.title}<span>&bull;</span> <em className="date">{work.years}</em>
          </p>
          <p dangerouslySetInnerHTML={{ __html: work.description.replace(/\n/g, '<br/>') }} />
        </div>
      ));
      const skills = this.props.data.skills.map((skill) => {
        const className = 'bar-expand ' + skill.name.toLowerCase();
        return (
          <li key={skill.name}>
            <span style={{ width: skill.level }} className={className}></span>
            <em>{skill.name}</em>
          </li>
        );
      });

      return (
        <section id="resume">
          <div className="row education">
            <div className="three columns header-col">
              <h1><span>Education</span></h1>
            </div>
            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">
                  {education}
                </div>
              </div>
            </div>
          </div>

          <div className="row work">
            <div className="three columns header-col">
              <h1><span>Work</span></h1>
            </div>
            <div className="nine columns main-col">
              {work}
            </div>
          </div>

          <div className="row skill">
            <div className="three columns header-col">
              <h1><span>Skills</span></h1>
            </div>
            <div className="nine columns main-col">
              <p>{skillmessage}</p>
              <div className="bars">
                <ul className="skills">
                  {skills}
                </ul>
              </div>
            </div>
          </div>
        </section>
      );
    }
    return null;
  }
}

export default Resume;
