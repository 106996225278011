import React, { Component } from "react";

class About extends Component {
  render() {
    if (this.props.data) {
      var name = this.props.data.name;
      var profilepic = "images/Igor.jpg";
      var bio = this.props.data.bio;
      var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var phone = this.props.data.phone;
      var email = this.props.data.email;
    }

    return (
      <section id="about">
        <div className="row">
          <div className="three columns">
            <img
              className="profile-pic"
              src={profilepic}
              alt="Igor Bachev Profile Pic"
            />
          </div>
          <div className="nine columns main-col">
            <h2>About Me</h2>
            <p class="compact-text">
              I’m Igor, a Full Stack Developer with 4.5 years of experience,
              specializing in Node.js, Nest, ExpressJS, React, Next, MySQL, MongoDB and
              PostgreSQL. I have a strong focus on building high-performing,
              scalable applications, and I’m actively exploring AI integration
              in my side projects. I’ve also developed mobile apps, backends,
              databases, and web apps, managing full project lifecycles from
              start to finish.
            </p>

            <p class="compact-text">
              Currently based in Strumica, Macedonia, I’m involved in both
              full-time development and freelance work. Over the years, I’ve had
              the opportunity to collaborate on projects across multiple
              countries, including the UK, Switzerland, and Germany. This
              experience has allowed me to cultivate a diverse skill set while
              working closely with designers, back-end developers, and
              marketers.
            </p>

            <p class="compact-text">
              I pride myself on being adaptable, responsible, and open to
              tackling new challenges in both existing and greenfield projects.
              My love for travel has taken me to places like the USA, where I
              visited twice in 2024, expanding my global perspective.
            </p>

            <p class="compact-text">
              I also have strong organizational skills, having managed several
              small projects from backend development to deployment.
            </p>

            <p class="compact-text">
              Check out my portfolio to see some of my latest work, including a
              portfolio project that integrates AI-driven technologies. Let’s
              connect and discuss how we can bring your next idea to life!
            </p>

            <div className="row">
              <div className="columns contact-details">
                <h2>Contact Details</h2>
                <p className="address">
                  <span>{name}</span>
                  <br />
                  <span>
                    {street}
                    <br />
                    {city} {state}, {zip}
                  </span>
                  <br />
                  <span>{phone}</span>
                  <br />
                  <span>{email}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
